<template>
  <!-- Error page-->
  <div class="misc-wrapper" style="min-height: 100px">
    <div class="misc-inner">
      <div class="text-center d-flex flex-column align-items-center">
        <h2 class="mb-1">
          ¡UPS! 😖 Ha habido un error.
        </h2>

        <b-button
          variant="primary"
          class="mb-2 btn-sm-block w-25"
          @click="exploreLocation"
        >
          Ir a Nectios
        </b-button>

        <!-- image -->
        <b-img fluid :src="imgUrl" alt="Error page" />
      </div>
    </div>
  </div>
  <!-- / Error page-->
</template>

<script>
import { BLink, BButton, BImg } from 'bootstrap-vue';
import ErrorDarkPlaceholder from '@/assets/images/pages/error-dark.svg';
import ErrorPlaceholder from '@/assets/images/pages/error.svg';
import BrandLogo from '@/assets/images/brand/brand-logo.svg';
import BrandFavicon from '@/assets/images/brand/brand-favicon.png';
import store from '@/store/index';
import UnauthorizedPlaceholder from '@/assets/images/placeholders/light/acceso_restringido.svg';

export default {
  components: {
    BLink,
    BButton,
    BImg,
  },
  data() {
    return {
      downImg: ErrorPlaceholder,
      unauthorizedImg: UnauthorizedPlaceholder,
      from: null,
    };
  },
  computed: {

    errorType() {
      return this.$route.params.error;
    },
    brand() {
      return BrandLogo;
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        return ErrorDarkPlaceholder;
      }
      if (this.errorType === 'unauthorized-user') {
        return this.unauthorizedImg;
      }
      return this.downImg;
    },
    failedUrl() {
      if (!this.from || !this.from.name) {
        return '';
      }

      return `${document.location.protocol}//${document.location.host}${this.from.path}`;
    },
    currentCollectiveSlug() {
      if (!this.from || !this.from.name) {
        return '';
      }
      return this.from.params?.communityId;
    },
  },
  created() {
    const links = document.querySelectorAll("link[rel~='icon']");
    for (let index = 0; index < links.length; index += 1) {
      const link = links[index];
      link.href = BrandFavicon;
    }
    /* if (this.errorType !== '404') setTimeout(() => this.exploreLocation(), 3000); */
  },
  beforeRouteEnter(to, from, next) {
    // eslint-disable-next-line no-param-reassign
    next((vm) => {
      vm.from = from;
    });
  },
  methods: {
    exploreLocation() {
      return this.$router.replace({
        name: 'Nectios',
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
.brand {
  height: 50px;
}
</style>
